import React, { useState } from "react";
import arrow from "../../assets/images/triangle@3x.png";

export default function Requirements() {
  const [profession, setProfession] = useState("Physical Therapist");

  const changeHandler = (event) => {
    const nam = event.target.name;
    const val = event.target.value;
    if (nam === "profession") {
      setProfession(val);
    }
  };

  console.log(profession)

  if (profession === "Physical Therapist" || profession === "Occupational Therapist" || profession === "Speech-Language Pathologist" || profession === "Special Instructor") {
    return (
      <div>
        <div className="">
          <h1 className="text-4xl tracking-wide text-center pb-12">
            Credential Check List
          </h1>

          <div className="grid grid-cols-12">
            <select
              onChange={changeHandler}
              name="profession"
              className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 text-sm h-10 p-2 mb-6 border border-veryLightGrey "
              style={{
                background: `url(${arrow}) no-repeat`,
                backgroundSize: 12,
                WebkitAppearance: "none",
                backgroundPositionY: "14px",
                backgroundPositionX: "97%",
              }}
            >
              <option defaultValue="Physical Therapist">
                Physical Therapist
              </option>
              <option value="Occupational Therapist">
                Occupational Therapist
              </option>
              <option value="Personal Care Assistant">
                Personal Care Assistant
              </option>
              <option value="Behavioral Support Specialist">Behavioral Support Specialist</option>
              <option value="Speech-Language Pathologist">
                Speech-Language Pathologist
              </option>
              <option value="Special Instructor">Special Instructor</option>
            </select>

            <ul className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10">
              <a className="hover:text-brightLightBlue" href="https://www.education.pa.gov/Documents/Teachers-Administrators/Background%20Checks/Arrest%20or%20Conviction%20form.pdf" target="_blank" rel="noopener noreferrer"><li>
                1. Act 24 Arrest/Conviction Report - For the written reporting of{" "}
                <span style={{ textDecoration: "underline" }}>
                  any arrest or conviction for an offense{" "}
                </span>
                enumerated under 24 P.S. §§1-111(e) and (f.1). Must be completed
                and submitted upon employment {" "}
              </li>
              </a>
              <br />
              <li>
                2. Child Abuse Clearance - Child Abuse Clearances{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire. </span>{" "} Request the document ONLY for the purpose of EMPLOYMENT.

                This document must be submitted for all Improve Employees. MUST be
                renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.compass.state.pa.us/cwis/public/home" target="_blank" rel="noopener noreferrer">Click on this text
                  and then “Create Individual Account” if you do not already have one. Follow the instructions on
                  the website to apply for the Child Abuse Clearance.</a>
              </li>
              <br />
              <li>
                3. PA Criminal History Clearance - PA Criminal History Clearances
                must be within 12 months of the date of hire. This document must
                be submitted for all Improve Employees. MUST be renewed every 60
                months. <a className="hover:text-brightLightBlue" href="https://epatch.pa.gov/home" target="_blank" rel="noopener noreferrer">Click on this text and “Submit a New Record Check.”</a>
              </li>
              <br />
<li>
4. Mandated Reporter Certificate. <a className="hover:text-brightLightBlue" href="https://www.reportabusepa.pitt.edu/" target="_blank" rel="noopener noreferrer"> Click on this text, and it will take you to the needed website.</a> Create an account and complete the training. Email the certificate of completion with the rest of the documents.
</li>
<br />
              <li>
                5. FBI Clearance - Clearance{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire.
                </span>{" "}
                All FBI reports must come from the PA <span style={{ textDecoration: "underline" }}>Dept of Education </span>or through
                the<span style={{ textDecoration: "underline" }}> Dept of Human Services</span>. This document must be submitted for
                all Improve Employees. MUST be renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.identogo.com/services/live-scan-fingerprinting" target="_blank" rel="noopener noreferrer">Click on this
                  text and “Select the state you need to be fingerprinted for.” Continue to follow the website
                  instructions to schedule an appointment. Please use code 1KG738 when applying.</a>
              </li>
              <br />


              <li>
                6. Professional Liability Insurance Copy (Not needed for Personal
                Care Assistant)
              </li>
              <br />
              <li>
                7. Professional Licensure Copy (High School Diploma for Personal
                Care Assistant applicants only)
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (profession === "Behavioral Support Specialist") {
    return (
      <div>
        <div className="">
          <h1 className="text-4xl tracking-wide text-center pb-12">
            Credential Check List
          </h1>

          <div className="grid grid-cols-12">
            <select
              onChange={changeHandler}
              name="profession"
              className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 text-sm h-10 p-2 mb-6 border border-veryLightGrey "
              style={{
                background: `url(${arrow}) no-repeat`,
                backgroundSize: 12,
                WebkitAppearance: "none",
                backgroundPositionY: "14px",
                backgroundPositionX: "97%",
              }}
            >
              <option defaultValue="Physical Therapist">
                Physical Therapist
              </option>
              <option value="Occupational Therapist">
                Occupational Therapist
              </option>
              <option value="Personal Care Assistant">
                Personal Care Assistant
              </option>
              <option value="Behavioral Support Specialist">Behavioral Support Specialist</option>
              <option value="Speech-Language Pathologist">
                Speech-Language Pathologist
              </option>
              <option value="Special Instructor">Special Instructor</option>
            </select>

            <ul className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10">
              <a className="hover:text-brightLightBlue" href="https://www.education.pa.gov/Documents/Teachers-Administrators/Background%20Checks/Arrest%20or%20Conviction%20form.pdf" target="_blank" rel="noopener noreferrer"><li>
                1. Act 24 Arrest/Conviction Report - For the written reporting of{" "}
                <span style={{ textDecoration: "underline" }}>
                  any arrest or conviction for an offense{" "}
                </span>
                enumerated under 24 P.S. §§1-111(e) and (f.1). Must be completed
                and submitted upon employment{" "}
              </li>
              </a>
              <br />
              <li>
                2. Child Abuse Clearance - Child Abuse Clearances{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire. </span>{" "} Request the document ONLY for the purpose of EMPLOYMENT.

                This document must be submitted for all Improve Employees. MUST be
                renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.compass.state.pa.us/cwis/public/home" target="_blank" rel="noopener noreferrer">Click on this text
                  and then “Create Individual Account” if you do not already have one. Follow the instructions on
                  the website to apply for the Child Abuse Clearance.</a>
              </li>
              <br />
              <li>
                3. PA Criminal History Clearance - PA Criminal History Clearances
                must be within 12 months of the date of hire. This document must
                be submitted for all Improve Employees. MUST be renewed every 60
                months. <a className="hover:text-brightLightBlue" href="https://epatch.pa.gov/home" target="_blank" rel="noopener noreferrer">Click on this text and “Submit a New Record Check.”</a>
              </li>
              <br />
<li>
4. Mandated Reporter Certificate. <a className="hover:text-brightLightBlue" href="https://www.reportabusepa.pitt.edu/" target="_blank" rel="noopener noreferrer"> Click on this text, and it will take you to the needed website.</a> Create an account and complete the training. Email the certificate of completion with the rest of the documents.
</li>
<br />
              <li>
                5. FBI Clearance - Clearance{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire.
                </span>{" "}
                All FBI reports must come from the PA <span style={{ textDecoration: "underline" }}>Dept of Education </span>or through
                the<span style={{ textDecoration: "underline" }}> Dept of Human Services</span>. This document must be submitted for
                all Improve Employees. MUST be renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.identogo.com/services/live-scan-fingerprinting" target="_blank" rel="noopener noreferrer">Click on this
                  text and “Select the state you need to be fingerprinted for.” Continue to follow the website
                  instructions to schedule an appointment. Please use code 1KG738 when applying.</a>
              </li>
              <br />
              <li>
                6.{" "}
                CPR Certification First Aid Certification must be
                current.
              </li>
              <br />
              <li>
              7. Bachelor of Science Certificate.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="">
          <h1 className="text-4xl tracking-wide text-center pb-12">
            Credential Check List
          </h1>

          <div className="grid grid-cols-12">
            <select
              onChange={changeHandler}
              name="profession"
              className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 text-sm h-10 p-2 mb-6 border border-veryLightGrey "
              style={{
                background: `url(${arrow}) no-repeat`,
                backgroundSize: 12,
                WebkitAppearance: "none",
                backgroundPositionY: "14px",
                backgroundPositionX: "97%",
              }}
            >
              <option defaultValue="Physical Therapist">
                Physical Therapist
              </option>
              <option value="Occupational Therapist">
                Occupational Therapist
              </option>
              <option value="Personal Care Assistant">
                Personal Care Assistant
              </option>
              <option value="Behavioral Support Specialist">Behavioral Support Specialist</option>
              <option value="Speech-Language Pathologist">
                Speech-Language Pathologist
              </option>
              <option value="Special Instructor">Special Instructor</option>
            </select>

            <ul className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10">
              <a className="hover:text-brightLightBlue" href="https://www.education.pa.gov/Documents/Teachers-Administrators/Background%20Checks/Arrest%20or%20Conviction%20form.pdf" target="_blank" rel="noopener noreferrer"><li>
                1. Act 24 Arrest/Conviction Report - For the written reporting of{" "}
                <span style={{ textDecoration: "underline" }}>
                  any arrest or conviction for an offense{" "}
                </span>
                enumerated under 24 P.S. §§1-111(e) and (f.1). Must be completed
                and submitted upon employment{" "}
              </li>
              </a>
              <br />
              <li>
                2. Child Abuse Clearance - Child Abuse Clearances{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire. </span>{" "} Request the document ONLY for the purpose of EMPLOYMENT.

                This document must be submitted for all Improve Employees. MUST be
                renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.compass.state.pa.us/cwis/public/home" target="_blank" rel="noopener noreferrer">Click on this text
                  and then “Create Individual Account” if you do not already have one. Follow the instructions on
                  the website to apply for the Child Abuse Clearance.</a>
              </li>
              <br />
              <li>
                3. PA Criminal History Clearance - PA Criminal History Clearances
                must be within 12 months of the date of hire. This document must
                be submitted for all Improve Employees. MUST be renewed every 60
                months. <a className="hover:text-brightLightBlue" href="https://epatch.pa.gov/home" target="_blank" rel="noopener noreferrer">Click on this text and “Submit a New Record Check.”</a>
              </li>
              <br />
<li>
4. Mandated Reporter Certificate. <a className="hover:text-brightLightBlue" href="https://www.reportabusepa.pitt.edu/" target="_blank" rel="noopener noreferrer"> Click on this text, and it will take you to the needed website.</a> Create an account and complete the training. Email the certificate of completion with the rest of the documents.
</li>
<br />
              <li>
                5. FBI Clearance - Clearance{" "}
                <span style={{ textDecoration: "underline" }}>
                  must be within 12 months of the date of hire.
                </span>{" "}
                All FBI reports must come from the PA <span style={{ textDecoration: "underline" }}>Dept of Education </span>or through
                the<span style={{ textDecoration: "underline" }}> Dept of Human Services</span>. This document must be submitted for
                all Improve Employees. MUST be renewed every 60 months. <a className="hover:text-brightLightBlue" href="https://www.identogo.com/services/live-scan-fingerprinting" target="_blank" rel="noopener noreferrer">Click on this
                  text and “Select the state you need to be fingerprinted for.” Continue to follow the website
                  instructions to schedule an appointment. Please use code 1KG738 when applying.</a>
              </li>
              <br />
              <li>
                6.{" "}
                CPR Certification First Aid Certification must be
                current.
              </li>
              <br />
              <li>
              7. Professional Licensure Copy (High School Diploma for Personal
  Care Assistant applicants only)
              </li>
            </ul>
          </div>
        </div>
      </div>
    );

  }
}

